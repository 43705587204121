import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  fontLight: 300,
  fontRegular: 400,
  fontMedium: 500,
  fontBold: 700,

  background: '#ffffff',
  foreground: '#1f1f1f',
  primary: '#7AD058',
  primaryDarker: '#73C375',
  wash: '#CCCCCC',
  washLight: '#f6f6f6',
  unavailable: '#fd644e',
  reserved: '#e6b43e',

  gradientPrimaryToPrimaryDarker:
    'linear-gradient(90deg, #7AD058 0%, #73C375 100%) 0% 0% no-repeat padding-box',
  gradientPrimaryDarkerToPrimaryDarkest:
    'linear-gradient(90deg, #73C375 0%, #62D264 100%) 0% 0% no-repeat padding-box',
};
