import React, { FC, useLayoutEffect } from 'react';
import { GlobalStyles } from '@theme/globalStyles';
import { css, ThemeProvider } from 'styled-components';
import { theme } from '@theme/theme';
import { Helmet } from 'react-helmet';
import Navigation from '@components/organisms/Navigation/Navigation';
import Footer from '@components/organisms/Footer/Footer';
import 'swiper/swiper.min.css';
import Contact from '@components/organisms/Contact/Contact';
import CookieNotice from '@rendpro/react-cookie-notice';
import favicon from '@assets/images/favicon.png';
import aos from 'aos';
import 'aos/dist/aos.css';
import { graphql, useStaticQuery } from 'gatsby';

export const BasicTemplate: FC<Props> = ({
  children,
  title,
  header: Header,
  className,
  changeStyleOffset,
}) => {
  useLayoutEffect(() => {
    aos.init({
      duration: 500,
      easing: 'ease-out-cubic',
      once: true,
    });
  });

  const {
    file: {
      childImageSharp: {
        gatsbyImageData: {
          images: {
            fallback: { src: ogImage },
          },
        },
      },
    },
  } = useStaticQuery(query);

  return (
    <>
      <Helmet>
        <title>
          {title
            ? `Osiedle Oleńki Park | ${title}`
            : 'Osiedle Oleńki Park | Kobyłka'}
        </title>
        <html lang="pl" />
        <meta
          name="google-site-verification"
          content="2OHmT0pflVY2T9jE-NeXlnnnHoaGiIIiaaRoGZ0NOiQ"
        />
        <meta
          name="description"
          content="Oleńki Park to zamknięte osiedle w miejscowości Kobyłka składające się z 4 domów bliźniaczych, w których znajduje się 8 lokali.
          Niespotykana dotąd w naszym mieście inwestycja, która jest tak blisko natury."
        />
        <link rel="icon" href={favicon} />

        <meta property="og:image" content={ogImage} />
        <meta
          property="og:title"
          content={
            title
              ? `Osiedle Oleńki Park | ${title}`
              : 'Osiedle Oleńki Park | Kobyłka'
          }
        />
        <meta
          property="og:description"
          content="Oleńki Park to zamknięte osiedle w miejscowości Kobyłka składające się z 4 domów bliźniaczych, w których znajduje się 8 lokali.
          Niespotykana dotąd w naszym mieście inwestycja, która jest tak blisko natury."
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <header>
          <Navigation changeStyleOffset={changeStyleOffset} />
          {Header && <Header />}
        </header>
        <main className={className}>{children}</main>
        <Contact />
        <Footer />
        <CookieNotice
          text="Ta strona używa plików cookie w celu usprawnienia i ułatwienia dostępu do serwisu oraz prowadzenia danych statystycznych. Dalsze korzystanie z tej witryny oznacza akceptację tego stanu rzeczy."
          buttonText="Akceptuję"
          links={[
            {
              name: 'Polityka prywatności',
              link: 'https://jakwylaczyccookie.pl/polityka-cookie/',
            },
            {
              name: 'Jak wyłączyć cookie',
              link: 'https://jakwylaczyccookie.pl/jak-wylaczyc-pliki-cookies/',
            },
            {
              name: 'Cyberbezpieczeństwo',
              link: 'https://nety.pl/cyberbezpieczenstwo',
            },
          ]}
          customStyles={css`
            & {
              z-index: 214748363;
            }

            button {
              font-family: 'Poppins', sans-serif !important;
              border-radius: 0;
            }
          `}
          foregroundColor="#1f1f1f"
          backgroundColor="#fff"
          linksColor="#7AD058"
          buttonBackgroundColor="#7AD058"
          buttonColor="#fff"
        />
      </ThemeProvider>
    </>
  );
};

const query = graphql`
  {
    file(name: { eq: "overhead" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, formats: JPG, layout: FIXED, width: 1200)
      }
    }
  }
`;

interface Props {
  title?: string;
  header?: React.FunctionComponent | React.ClassicComponentClass;
  className?: string;
  changeStyleOffset?: number;
}
