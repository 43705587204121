import React, { FC } from 'react';
import styled from 'styled-components';
import Icon from '@iconify/react';
import chevronIcon from '@iconify-icons/eva/chevron-up-fill';
import { useButtonEffects } from '@hooks/useButtonEffects';
import { rippleAnimation } from '@theme/rippleAnimation';
import { animateScroll } from 'react-scroll';

const StyledButton = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  outline: none;
  border: none;
  background: ${({ theme }) => theme.background};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  transition: background 0.3s ease-in-out, transform 0.3s ease-out;
  cursor: pointer;

  :hover {
    background: ${({ theme }) => theme.washLight};
  }

  :active {
    transform: scale(0.95);
    background: ${({ theme }) => theme.washLight};
  }

  .ripple {
    width: 160px;
    height: 160px;
    position: absolute;
    background: #dddddd;
    border-radius: 50%;
    transform: scale(0);
    pointer-events: none;
    opacity: 0;
    animation: ${rippleAnimation} 0.6s ease-out;
    transform-origin: center center;

    @media (min-width: 1025px) {
      width: 260px;
      height: 260px;
    }
  }

  @media (min-width: 1025px) {
    width: 75px;
    height: 75px;
  }
`;

const StyledIcon = styled(Icon)`
  font-size: 3rem;
  color: ${({ theme }) => theme.primary};

  @media (min-width: 1025px) {
    font-size: 5rem;
  }
`;

const Button: FC<Props> = ({ className }) => {
  const { onMouseDown } = useButtonEffects();

  const handleClick = () => animateScroll.scrollToTop();

  return (
    <StyledButton
      aria-label="Wróć do początku"
      className={className}
      onMouseDown={onMouseDown}
      onClick={handleClick}
    >
      <StyledIcon icon={chevronIcon} />
    </StyledButton>
  );
};

interface Props {
  className?: string;
}

export default Button;
