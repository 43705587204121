import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Availability } from '../../../../types/availability';
import Button from '@components/atoms/Button/Button';
import { Link } from 'gatsby';

const StyledWrapper = styled.div`
  position: absolute;
  display: none;
  flex-direction: column;
  align-items: center;
  z-index: 98;

  @media (min-width: 1025px) {
    display: flex;
  }
`;

const StyledHiddenElement = styled.div`
  width: 40%;
  height: 20px;
`;

const StyledInnerWrapper = styled.div`
  background: ${({ theme }) => theme.background};
  width: 270px;
  height: 310px;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const StyledLocalNumber = styled.h3`
  margin: 0;
  font-weight: ${({ theme }) => theme.fontRegular};
  font-size: 1.4rem;
  text-transform: uppercase;
  color: #000;
  letter-spacing: 1px;
`;

const StyledAvailability = styled.p<{ $availability: Availability }>`
  margin: 0;
  font-size: 2.4rem;
  font-weight: ${({ theme }) => theme.fontBold};
  color: ${({ theme }) => theme.primary};

  ${({ $availability }) =>
    $availability === Availability.RESERVED
      ? css`
          color: ${({ theme }) => theme.reserved};
        `
      : $availability === Availability.UNAVAILABLE &&
        css`
          color: ${({ theme }) => theme.unavailable};
        `}
`;

const StyledFeature = styled.div`
  font-size: 1.4rem;

  :first-of-type {
    margin-top: 3px;
  }

  span {
    font-weight: ${({ theme }) => theme.fontBold};
  }
`;

const StyledPrice = styled.div<{ $availability: Availability }>`
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 1.4rem;

  span {
    font-size: 3rem;
    color: ${({ theme }) => theme.primary};
    font-weight: ${({ theme }) => theme.fontBold};

    ${({ $availability }) =>
      $availability === Availability.RESERVED
        ? css`
            color: ${({ theme }) => theme.reserved};
          `
        : $availability === Availability.UNAVAILABLE &&
          css`
            color: ${({ theme }) => theme.unavailable};
          `}
  }
`;

const StyledButton = styled(Button).attrs(() => ({ secondary: true }))<{
  $availability: Availability;
}>`
  margin-top: 10px;
  font-weight: ${({ theme }) => theme.fontRegular};
  color: ${({ theme }) => theme.foreground};
  font-size: 1.4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 0;

  ${({ $availability }) =>
    $availability === Availability.RESERVED
      ? css`
          border: 1px solid ${({ theme }) => theme.reserved};

          :hover,
          :active {
            background: ${({ theme }) => theme.reserved};
          }
        `
      : $availability === Availability.UNAVAILABLE &&
        css`
          border: 1px solid ${({ theme }) => theme.unavailable};

          :hover,
          :active {
            background: ${({ theme }) => theme.unavailable};
          }
        `}
`;

const Box: FC<Props> = ({
  replaceNumber,
  number,
  price,
  values,
  availability,
  className,
}) => (
  <StyledWrapper className={className}>
    <StyledHiddenElement />
    <StyledInnerWrapper>
      <StyledLocalNumber>Lokal {replaceNumber || number}</StyledLocalNumber>
      <StyledAvailability $availability={availability}>
        {availability}
      </StyledAvailability>
      {values.map(({ name, value }) => (
        <StyledFeature key={name}>
          {name}: <span>{value}</span>
        </StyledFeature>
      ))}
      <StyledPrice $availability={availability}>
        Cena:{' '}
        <span>
          {new Intl.NumberFormat('pl-PL', {
            style: 'currency',
            currency: 'PLN',
            minimumFractionDigits: 0,
          }).format(price)}
        </span>
      </StyledPrice>
      <StyledButton
        forwardedAs={Link}
        to={`/lokal/${number.toLowerCase()}`}
        $availability={availability}
      >
        Zobacz więcej
      </StyledButton>
    </StyledInnerWrapper>
  </StyledWrapper>
);

interface Props {
  availability: Availability;
  values: { name: string; value: string }[];
  price: number;
  number: string;
  className?: string;
  replaceNumber?: string;
}

export default Box;
