import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Logo from '@components/atoms/Logo/Logo';
import Hamburger from '@components/atoms/Hamburger/Hamburger';
import List from '@components/organisms/Navigation/List/List';
import { useWindowScroll, useWindowSize } from 'react-use';
import Icon from '@iconify/react';
import smartphone from '@assets/icons/smartphone';
import { NAVIGATION_ITEMS } from '@config';

const StyledWrapper = styled.nav<{ $scrolled: boolean }>`
  position: fixed;
  height: 60px;
  left: 0;
  top: 0;
  z-index: 999;
  background: ${({ theme }) => rgba(theme.background, 0.9)};
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;
  transition: background 0.4s ease-in-out, box-shadow 0.4s ease-in-out;

  ${({ $scrolled, theme }) =>
    $scrolled &&
    css`
      background: ${theme.background};
      box-shadow: 0 4px 40px -10px rgba(0, 0, 0, 0.25);
    `};
  
  @media (min-width: 1025px) {
    height: 125px;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 0 5%;
    transition: height .4s ease-in-out;
    
    ${({ $scrolled }) =>
      $scrolled &&
      css`
        height: 100px;
      `}
  }
}
`;

const StyledLogo = styled(Logo)`
  width: 45px;
  height: 45px;

  @media (min-width: 1025px) {
    width: 70px;
    height: 70px;
  }
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  margin-right: auto;
  position: absolute !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media (min-width: 1025px) {
    position: static !important;
    transform: unset;
    margin-right: unset;
  }
`;

const StyledHamburger = styled(Hamburger)`
  @media (min-width: 1025px) {
    display: none;
  }
`;

const StyledContactInfoWrapper = styled.div`
  display: none;

  @media (min-width: 1025px) {
    display: flex;
  }
`;

const StyledContactInfoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: center;
  margin-right: 20px;
`;

const StyledContactInfoLink = styled.a`
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fontMedium};
  line-height: 1.3;
  color: inherit;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }

  :first-of-type {
    font-weight: ${({ theme }) => theme.fontBold};
    color: ${({ theme }) => theme.primaryDarker};
    margin-bottom: 2px;
  }
`;

const StyledIcon = styled(Icon)`
  font-size: 5.5rem;
  color: ${({ theme }) => theme.primaryDarker};
`;

const Navigation: FC<Props> = ({ changeStyleOffset }) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const { y } = useWindowScroll();
  const { width, height } = useWindowSize();
  const [offsets, setOffsets] = useState<number[]>([]);
  const [currentPlace, setCurrentPlace] = useState<number>(0);

  const {
    datoCmsSetting,
  }: {
    datoCmsSetting: {
      email: string;
      phoneNumber: string;
    };
  } = useStaticQuery(query);

  const win: Window = (typeof window !== 'undefined' &&
    window) as any as Window;

  const checkOffsets = () => {
    offsets.forEach((offset, index) => {
      if (win.pageYOffset + 250 >= offset && currentPlace !== -1) {
        setCurrentPlace(index);
      }
    });
  };

  useEffect(() => {
    let offsets: number[] = [];

    NAVIGATION_ITEMS.forEach(({ to }, index) => {
      const selector = to.charAt(0) === '/' ? to.substr(1, to.length) : to;

      if (selector === '') {
        offsets = [...offsets, 0];
        return;
      }

      offsets = [
        ...offsets,
        // @ts-ignore
        document.querySelector(selector)?.getBoundingClientRect().top +
          win.pageYOffset,
      ];

      if (Number.isNaN(offsets[index])) {
        setCurrentPlace(-1);
      }
    });

    setOffsets(offsets);

    return () => {
      setOffsets([]);
    };
  }, []);

  useEffect(() => {
    if (
      NAVIGATION_ITEMS.length === offsets?.length &&
      width >= 1025 &&
      currentPlace !== -1
    ) {
      document.addEventListener('scroll', checkOffsets);
    }

    return () => {
      document.removeEventListener('scroll', checkOffsets);
    };
  }, [NAVIGATION_ITEMS.length === offsets?.length && width >= 1025]);

  const handleHamburgerClick = () => setIsActive((prevState) => !prevState);

  const scrolled = y >= (changeStyleOffset || height - 100);

  return (
    <StyledWrapper $scrolled={scrolled}>
      <StyledHamburger
        isActive={isActive}
        onClick={handleHamburgerClick}
        ariaLabelledBy="nav_menu"
      />

      <StyledContactInfoWrapper>
        <StyledContactInfoTextWrapper>
          <StyledContactInfoLink
            href={`tel:${datoCmsSetting.phoneNumber.replace(/\s/g, '')}`}
          >
            {datoCmsSetting.phoneNumber}
          </StyledContactInfoLink>
          <StyledContactInfoLink href={`mailto:${datoCmsSetting.email}`}>
            {datoCmsSetting.email}
          </StyledContactInfoLink>
        </StyledContactInfoTextWrapper>
        <StyledIcon icon={smartphone} />
      </StyledContactInfoWrapper>

      <List
        isActive={isActive}
        setActive={setIsActive}
        ariaLabelledBy="nav_menu"
        scrolled={scrolled}
        currentPlace={currentPlace}
      />

      <StyledLink aria-label="Strona Główna" to="/">
        <StyledLogo />
      </StyledLink>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    datoCmsSetting {
      email
      phoneNumber
    }
  }
`;

interface Props {
  changeStyleOffset?: number;
}

export default Navigation;
