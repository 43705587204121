import React, { FC } from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  display: inline-block;
  width: 150px;

  @media (min-width: 1025px) {
    width: 250px;
  }
`;

const StyledInnerWrapper = styled.label`
  position: relative;
  width: 100%;
`;

const StyledInput = styled.input`
  border: 0;
  border-bottom: 1px solid ${({ theme }) => theme.wash};
  font-size: 1.4rem;
  padding: 10px 8px;
  outline: none;
  transition: border-bottom 0.2s ease-in-out;
  width: 100%;
  background: transparent;

  :focus {
    border-bottom: 1px solid ${({ theme }) => theme.primary};
  }

  @media (min-width: 1025px) {
    font-size: 2rem;
  }
`;

const StyledText = styled.span`
  font-size: 1.4rem;
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  transform-origin: top left;
  transition: transform 0.2s ease-in-out;
  color: ${({ theme }) => theme.wash};
  pointer-events: none;

  span {
    color: ${({ theme }) => theme.primary};
    margin-right: 3px;
  }

  ${StyledInput}:not(:placeholder-shown) ~ & {
    transform: scale(0.8) translate(-8px, -170%);
  }

  @media (min-width: 1025px) {
    font-size: 2rem;

    span {
      margin-right: 5px;
    }
  }
`;

const StyledError = styled.span`
  color: ${({ theme }) => theme.unavailable};
  margin-top: 10px;
  display: block;
  font-size: 1.4rem;

  @media (min-width: 1025px) {
    font-size: 1.6rem;
  }
`;

const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      placeholder,
      required,
      name,
      value,
      className,
      error,
      onChange,
      type = 'text',
    },
    ref
  ) => (
    <StyledWrapper className={className}>
      <StyledInnerWrapper>
        <StyledInput
          placeholder=" "
          value={value}
          name={name}
          onChange={onChange}
          type={type}
          autoComplete="off"
          ref={ref}
        />
        <StyledText>
          {required && <span>*</span>}
          {placeholder}
        </StyledText>
      </StyledInnerWrapper>
      {error && <StyledError>{error}</StyledError>}
    </StyledWrapper>
  )
);

interface Props {
  required?: boolean;
  placeholder?: string;
  name?: string;
  value?: string;
  className?: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  type?: 'text' | 'email';
  error?: string;
}

export default Input;
