import React, { FC } from 'react';
import styled from 'styled-components';
import Icon from '@iconify/react';

const StyledWrapper = styled.div`
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.fontBold};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: inherit;
  text-decoration: none;

  @media (min-width: 1025px) {
    font-size: 2.4rem;
    flex-direction: row;
    justify-content: flex-start;
  }
`;

const StyledIconWrapper = styled.div`
  width: 90px;
  height: 90px;
  background: ${({ theme }) => theme.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;

  @media (min-width: 1025px) {
    margin-bottom: 0;
    margin-right: 20px;
  }
`;

const StyledText = styled.div`
  @media (min-width: 1025px) {
    max-width: 220px;
    text-align: left;
  }
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.background};
  font-size: 6rem;
`;

const ContactBlock: FC<Props> = ({
  icon,
  children,
  className,
  href,
  as,
  target,
  rel,
}) => (
  <StyledWrapper
    className={className}
    href={href}
    as={as as any}
    target={target}
    rel={rel}
  >
    <StyledIconWrapper>
      <StyledIcon icon={icon} />
    </StyledIconWrapper>
    <StyledText dangerouslySetInnerHTML={{ __html: children }} />
  </StyledWrapper>
);

interface Props {
  icon: any;
  className?: string;
  href?: string;
  as?: string;
  target?: string;
  rel?: string;
  children: string;
}

export default ContactBlock;
