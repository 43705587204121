import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { NAVIGATION_ITEMS } from '@config';
import { rgba } from 'polished';
import { goToSection } from '@utils/goToSection';

const StyledWrapper = styled.ul<{ $isActive: boolean; $scrolled: boolean }>`
  position: absolute;
  left: 0;
  top: 100%;
  background: ${({ theme }) => rgba(theme.background, 0.9)};
  display: flex;
  width: 100%;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  border-top: 1px solid #bebebe;
  padding: 10px 0;
  transform: scaleY(0);
  opacity: 0.5;
  pointer-events: none;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out,
    background 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
  transform-origin: top left;

  ${({ $isActive }) =>
    $isActive &&
    css`
      transform: scaleY(1);
      opacity: 1;
      pointer-events: all;
    `}

  ${({ $scrolled, theme }) =>
    $scrolled &&
    css`
      background: ${theme.background};
      box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.05);
    `};

  @media (min-width: 1025px) {
    position: static;
    transform: unset;
    background: transparent;
    flex-direction: row;
    opacity: 1;
    border: 0;
    pointer-events: all;
    font-size: 2rem;
    padding: 0;
    width: unset;
    font-weight: ${({ theme }) => theme.fontMedium};
    margin: auto;
    box-shadow: none;
    transition: none;
  }

  @media (min-width: 1174px) {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  } ;
`;

const StyledItem = styled.li``;

const StyledLink = styled(Link)<{ $current: boolean }>`
  color: inherit;
  text-decoration: none;
  padding: 10px 25px;
  display: block;
  text-align: center;

  @media (min-width: 1025px) {
    padding: 0;
    margin-right: 30px;

    ${({ $current, theme }) =>
      $current &&
      css`
        color: ${theme.primaryDarker};
      `}
  }
`;

const List: FC<Props> = ({
  className,
  isActive,
  ariaLabelledBy,
  scrolled,
  setActive,
  currentPlace,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>, to: string) => {
    goToSection(e, to);
    setActive(false);
  };

  return (
    <StyledWrapper
      className={className}
      $isActive={isActive}
      aria-labelledby={ariaLabelledBy}
      $scrolled={scrolled}
    >
      {NAVIGATION_ITEMS.map(({ name, to }, index) => (
        <StyledItem key={to}>
          <StyledLink
            to={to}
            onClick={(e) => handleClick(e, to)}
            $current={currentPlace === index}
          >
            {name}
          </StyledLink>
        </StyledItem>
      ))}
    </StyledWrapper>
  );
};

interface Props {
  className?: string;
  isActive: boolean;
  ariaLabelledBy: string;
  scrolled: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  currentPlace: number;
}

export default List;
