import React, { FC } from 'react';
import styled from 'styled-components';
import InteractiveVisualization from '@components/molecules/InteractiveVisualization/InteractiveVisualization';
import { ChildImageSharpImage } from '../../../types/childImageSharpImage';
import Icon from '@iconify/react';
import plant from '@assets/icons/plant';

const StyledWrapper = styled.section`
  width: 100%;
  max-width: 1600px;
  margin: 0 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (min-width: 1025px) {
    margin: 0 auto 180px;
  }
`;

const StyledHeadline = styled.h2`
  font-size: 2.4rem;
  margin: 0 0 20px;

  @media (min-width: 1025px) {
    font-size: 4.5rem;
    margin-bottom: 70px;
  }
`;

const StyledParagraph = styled.p`
  text-align: center;
  margin-bottom: 10px;
  margin-top: 0;

  @media (min-width: 1025px) {
    font-size: 2rem;
    text-align: left;
    width: 100%;
    margin-bottom: 15px;
  }
`;

const StyledPlant = styled(Icon)`
  position: absolute;
  z-index: -1;
  right: 0;
  top: 0;
  transform: translateY(20%) !important;
  font-size: 17rem;

  @media (min-width: 1025px) {
    font-size: 40rem;
    transform: translateY(-30%) !important;
  }
`;

const Houses: FC<Props> = ({ image, className }) => (
  <StyledWrapper className={className}>
    <StyledHeadline id="houses" data-aos="fade-up">
      Domy
    </StyledHeadline>
    <StyledParagraph data-aos="fade-up">
      Wybierz interesujący Cię budynek, aby zobaczyć szczegóły oferty
    </StyledParagraph>

    <InteractiveVisualization image={image} data-aos="fade-up" />

    <StyledPlant icon={plant} />
  </StyledWrapper>
);

interface Props {
  image: ChildImageSharpImage;
  className?: string;
}

export default Houses;
