export const NAVIGATION_ITEMS = [
  { name: 'Start', to: '/' },
  { name: 'O osiedlu', to: '/#about_estate' },
  { name: 'Domy', to: '#houses' },
  { name: 'Lokalizacja', to: '/#location' },
  { name: 'Kontakt', to: '#contact' },
];

export const GOOGLE_MAP = {
  coords: {
    lat: 51.4162206,
    lng: 20.3007326,
  },
  zoom: 16,
};
