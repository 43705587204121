import React, { FC } from 'react';
import styled from 'styled-components';
import Icon from '@iconify/react';
import menuIcon from '@iconify-icons/heroicons-solid/menu-alt-1';
import closeIcon from '@iconify-icons/heroicons-solid/x';

const StyledButton = styled.button`
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
`;

const StyledIcon = styled(Icon)`
  font-size: 3rem;
  color: ${({ theme }) => theme.foreground};
`;

const Hamburger: FC<Props> = ({
  onClick,
  isActive = false,
  className,
  ariaLabelledBy,
}) => {
  return (
    <StyledButton
      onClick={onClick}
      aria-label={!isActive ? 'Otwórz nawigację' : 'Zamknij nawigacje'}
      aria-labelledby={ariaLabelledBy}
      className={className}
    >
      <StyledIcon icon={!isActive ? menuIcon : closeIcon} />
    </StyledButton>
  );
};

interface Props {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isActive?: boolean;
  className?: string;
  ariaLabelledBy: string;
}

export default Hamburger;
