import { animateScroll } from 'react-scroll';

export const scrollTo = (target: string | HTMLElement) => {
  let element;

  if (typeof target === 'string') {
    if (target === '') {
      animateScroll.scrollToTop({ duration: 1000 });
      return;
    }

    element = document.querySelector(target);
  } else {
    element = target;
  }

  if (!element) return;

  const win: Window = (typeof window !== 'undefined' && window) as any;

  const scrollTop: number =
    element.getBoundingClientRect().top +
    window.pageYOffset -
    (win.innerWidth >= 1024 ? 120 : 90);

  animateScroll.scrollTo(scrollTop, {
    duration: 1000,
  });
};
