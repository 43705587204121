import React from 'react';
import styled, { css } from 'styled-components';
import ContactBlock from '@components/atoms/ContactBlock/ContactBlock';
import emailIcon from '@iconify-icons/ic/email';
import phoneIcon from '@iconify-icons/ic/phone';
import locationIcon from '@iconify-icons/ic/location-on';
import Form from '@components/molecules/Form/Form';
import Icon from '@iconify/react';
import plantIcon from '@assets/icons/plant';
import leafIcon from '@assets/icons/leaf';
import { graphql, useStaticQuery } from 'gatsby';

const StyledWrapper = styled.section`
  width: 100%;
  padding: 0 5%;
  margin: 0 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (min-width: 1025px) {
    max-width: 1300px;
    padding: unset;
    width: 93%;
    margin: 0 auto 160px;
  }

  @media (min-width: 1130px) {
    width: 90%;
  }
`;

const StyledHeadline = styled.h2`
  font-size: 2.4rem;
  margin: 0 0 20px;
  text-align: center;

  @media (min-width: 1025px) {
    font-size: 4.5rem;
    margin-bottom: 70px;
  }
`;

const StyledInnerWrapper = styled.div`
  @media (min-width: 1025px) {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
  }
`;

const StyledContactBlocksWrapper = styled.div`
  @media (min-width: 1025px) {
    display: flex;
    align-items: center;
  }
`;

const StyledContactBlocksInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1025px) {
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const StyledContactBlocksLine = styled.span`
  @media (min-width: 1130px) {
    height: 450px;
    width: 18px;
    border-radius: 18px;
    background: ${({ theme }) => theme.primary};
    display: block;
    margin-right: 50px;
  }
`;

const StyledContactBlock = styled(ContactBlock)`
  margin-top: 50px;
  width: 60%;
  text-align: center;

  :first-of-type {
    margin-top: 70px;
  }

  :nth-of-type(2) {
    margin-bottom: 20px;
  }

  @media (min-width: 1025px) {
    width: 100%;
    margin: 0 0 50px !important;

    :last-of-type {
      margin: 0 !important;
    }
  }
`;

const StyledPlant = styled(Icon)`
  position: absolute;
  font-size: 20rem;
  bottom: 0;
  z-index: -1;
  left: 0;
  transform: translate(-5%, 40%) !important;

  @media (min-width: 322px) {
    font-size: 30rem;
  }

  @media (min-width: 1025px) {
    transform: translate(-5%, 40%) !important;
    font-size: 50rem;
  }

  @media (min-width: 1456px) {
    transform: translate(-15%, 40%) !important;
  }

  @media (min-width: 1660px) {
    transform: translate(-35%, 40%) !important;
  } ;
`;

const StyledLeaf = styled(Icon)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  display: none;

  @media (min-width: 1536px) {
    display: block;
    transform: translate(38%, -30%) rotateY(180deg) !important;
    font-size: 25rem;
  }
`;

const Contact = () => {
  const {
    datoCmsSetting,
  }: {
    datoCmsSetting: {
      email: string;
      phoneNumber: string;
      address: string;
      rodoAddress: string;
      addressLink: string;
    };
  } = useStaticQuery(query);

  return (
    <StyledWrapper>
      <StyledHeadline id="contact" data-aos="fade-up">
        Zapraszamy do kontaktu
      </StyledHeadline>
      <StyledInnerWrapper>
        <Form rodoAddress={datoCmsSetting.rodoAddress} />
        <StyledContactBlocksWrapper>
          <StyledContactBlocksLine />
          <StyledContactBlocksInnerWrapper>
            <StyledContactBlock
              icon={emailIcon}
              href={`mailto:${datoCmsSetting.email}`}
              forwardedAs="a"
              target="_blank"
              rel="noopener"
            >
              {datoCmsSetting.email}
            </StyledContactBlock>
            <StyledContactBlock
              icon={phoneIcon}
              href={`tel:${datoCmsSetting.phoneNumber.replace(/\s/g, '')}`}
              forwardedAs="a"
              rel="noopener"
            >
              {datoCmsSetting.phoneNumber}
            </StyledContactBlock>
            <StyledContactBlock
              icon={locationIcon}
              href={datoCmsSetting.addressLink}
              forwardedAs="a"
              target="_blank"
              rel="noopener"
            >
              {datoCmsSetting.address}
            </StyledContactBlock>
          </StyledContactBlocksInnerWrapper>
        </StyledContactBlocksWrapper>
      </StyledInnerWrapper>

      <StyledPlant icon={plantIcon} />
      <StyledLeaf icon={leafIcon} />
    </StyledWrapper>
  );
};

const query = graphql`
  {
    datoCmsSetting {
      email
      address
      addressLink
      rodoAddress
      phoneNumber
    }
  }
`;

export default Contact;
