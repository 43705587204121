import React, { FC } from 'react';
import styled from 'styled-components';
import Icon from '@iconify/react';
import checkIcon from '@iconify-icons/bi/check';

const StyledWrapper = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  margin: 0;
  cursor: pointer;
`;

const StyledCheckbox = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.foreground};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.background};
  position: relative;

  ${StyledInput}:checked ~ & {
    background: ${({ theme }) => theme.primary};
    border: 1px solid ${({ theme }) => theme.primary};
  }
`;

const StyledIcon = styled(Icon)`
  font-size: 2.2rem;
  color: ${({ theme }) => theme.background};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
`;

const Checkbox = React.forwardRef<HTMLInputElement, Props>(
  ({ className, name, required, onChange, value, checked }, ref) => (
    <StyledWrapper className={className}>
      <StyledInput
        name={name}
        type="checkbox"
        onChange={onChange}
        checked={checked}
        value={value}
        ref={ref}
      />
      <StyledCheckbox>
        <StyledIcon icon={checkIcon} />
      </StyledCheckbox>
    </StyledWrapper>
  )
);

interface Props {
  className?: string;
  name?: string;
  required?: boolean;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  checked?: boolean;
  value?: any;
}

export default Checkbox;
