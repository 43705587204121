import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { ChildImageSharpImage } from '../../../types/childImageSharpImage';
import Checkbox from '@components/atoms/Checkbox/Checkbox';
import Box from '@components/molecules/InteractiveVisualization/Box/Box';
import { CSSTransition } from 'react-transition-group';
import { Availability } from '../../../types/availability';

const StyledWrapper = styled.article`
  width: 100%;
  margin: auto;
`;

const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  max-width: 482px;
  margin: auto;
`;

const StyledList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  justify-content: flex-end;

  margin: 0;

  :first-of-type {
    margin-right: 15px;
    justify-content: flex-start;
  }

  :last-of-type {
    margin-right: -10px;

    @media (min-width: 482px) {
      margin-right: -15px;
    }
  }

  @media (min-width: 1025px) {
    display: none;
  }
`;

const StyledItem = styled.li`
  display: flex;
`;

const StyledLink = styled(Link)<{ $availability: Availability }>`
  text-decoration: none;
  color: ${({ theme }) => theme.background};
  background: ${({ theme }) => theme.primary};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  margin-right: 10px;
  margin-bottom: 10px;

  @media (min-width: 482px) {
    width: 35px;
    height: 35px;
    margin-right: 15px;
  }

  &.enter {
    opacity: 0;
    transform: scale(0.9);
  }

  &.enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }

  &.exit {
    opacity: 1;
  }

  &.exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }

  ${({ $availability }) =>
    $availability === Availability.RESERVED
      ? css`
          background: ${({ theme }) => theme.reserved};
        `
      : $availability === Availability.UNAVAILABLE &&
        css`
          background: ${({ theme }) => theme.unavailable};
        `}
`;

const StyledImageWrapper = styled.div`
  position: relative;
`;

const StyledIndicationsWrapper = styled.div<{
  $first?: boolean;
  $second?: boolean;
}>`
  position: absolute;
  display: inline-flex;
  flex-shrink: 0;
  width: 30%;
  z-index: 5;

  :nth-of-type(2) {
    z-index: 10;
  }

  ${({ $first }) =>
    $first &&
    css`
      top: 31%;
      left: 21%;
      transform: rotate(-24deg);

      @media (min-width: 1456px) {
        top: 29%;
        left: 22%;
      }
    `}

  ${({ $second }) =>
    $second &&
    css`
      top: 33%;
      left: 50%;
      transform: rotate(-47deg);
    `}
`;

const StyledIndicationWrapper = styled.div<{
  $first?: boolean;
  $second?: boolean;
  $preventFromDisplay: boolean;
}>`
  position: relative;
  opacity: 1;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-out;

  ${({ $preventFromDisplay }) =>
    $preventFromDisplay &&
    css`
      opacity: 0;
      pointer-events: none;
    `}

  ${({ $first, $preventFromDisplay }) =>
    $first &&
    css`
      transform: rotate(24deg) scale(1);

      ${$preventFromDisplay &&
      css`
        transform: rotate(24deg) scale(0.6);
      `}

      :first-of-type {
        margin-right: 20%;

        @media (min-width: 1025px) {
          margin-right: 25%;
        }
      }

      :nth-of-type(2) {
        margin-right: 8%;

        @media (min-width: 290px) {
          margin-right: 12%;
        }

        @media (min-width: 350px) {
          margin-right: 17%;
        }

        @media (min-width: 402px) {
          margin-right: 15%;
        }

        @media (min-width: 482px) {
          margin-right: 18%;
        }

        @media (min-width: 582px) {
          margin-right: 15%;
        }

        @media (min-width: 722px) {
          margin-right: 22%;
        }
      }

      :nth-of-type(3) {
        margin-right: 5%;

        @media (min-width: 472px) {
          margin-right: 9%;
        }

        @media (min-width: 870px) and (max-width: 1024px) {
          margin-right: 15%;
        }
      }
    `}

  ${({ $second, $preventFromDisplay }) =>
    $second &&
    css`
      transform: rotate(47deg);

      ${$preventFromDisplay &&
      css`
        transform: rotate(47deg) scale(0.6);
      `}

      :first-of-type {
        margin-right: 12%;

        @media (min-width: 460px) {
          margin-right: 17%;
        }

        @media (min-width: 912px) {
          margin-right: 23%;
        }
      }

      :nth-of-type(2) {
        margin-right: 3%;

        @media (min-width: 325px) {
          margin-right: 7%;
        }

        @media (min-width: 460px) {
          margin-right: 10%;
        }

        @media (min-width: 1025px) {
          margin-right: 12%;
        }
      }

      :nth-of-type(3) {
        margin-right: 2%;

        @media (min-width: 325px) {
          margin-right: 6%;
        }
      }
    `}
`;

const StyledIndication = styled(Link)<{ $availability: Availability }>`
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.background};
  border-radius: 50%;
  font-weight: ${({ theme }) => theme.fontLight};
  font-size: 0.8rem;
  text-decoration: none;

  :hover {
    filter: brightness(90%);
  }

  @media (min-width: 402px) {
    width: 15px;
    height: 15px;
    font-size: 1rem;
  }

  @media (min-width: 582px) {
    width: 20px;
    height: 20px;
    font-size: 1.2rem;
  }

  @media (min-width: 1025px) {
    width: 25px;
    height: 25px;
    font-size: 1.6rem;
  }

  @media (min-width: 1456px) {
    width: 30px;
    height: 30px;
  }

  ${({ $availability }) =>
    $availability === Availability.RESERVED
      ? css`
          background: ${({ theme }) => theme.reserved};
        `
      : $availability === Availability.UNAVAILABLE &&
        css`
          background: ${({ theme }) => theme.unavailable};
        `}
`;

const StyledGatsbyImage = styled(GatsbyImage)``;

const StyledBottomWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (min-width: 1025px) {
    margin-top: 30px;
  }
`;

const StyledLegendWrapper = styled.div`
  @media (min-width: 1025px) {
    display: flex;
  }
`;

const StyledLegendCircle = styled.div<{ $color: 'green' | 'orange' | 'red' }>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #3ccb3e;
  margin-right: 7px;

  ${({ $color }) =>
    $color === 'orange'
      ? css`
          background: ${({ theme }) => theme.reserved};
        `
      : $color === 'red' &&
        css`
          background: ${({ theme }) => theme.unavailable};
        `};

  @media (min-width: 1025px) {
    width: 20px;
    height: 20px;
    font-size: 2rem;
  }
`;

const StyledCheckboxWraper = styled.label`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  cursor: pointer;
  width: 40%;

  span {
    flex: 1;
  }

  @media (min-width: 360px) {
    width: unset;
  }

  @media (min-width: 1025px) {
    font-size: 2rem;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 8px;
`;

const Legend: FC<{ className?: string; color?: 'green' | 'orange' | 'red' }> =
  ({ className, children, color = 'green' }) => (
    <div className={className}>
      <StyledLegendCircle $color={color} />
      {children}
    </div>
  );

const StyledLegend = styled(Legend)<{ $preventFromDisplay?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  transform: scale(1);
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-out;

  :last-of-type {
    transition-delay: 0.2s;
  }

  ${({ $preventFromDisplay }) =>
    $preventFromDisplay &&
    css`
      opacity: 0;
      pointer-events: none;
      transform: scale(0.8);
    `};

  @media (min-width: 1025px) {
    font-size: 2rem;
    margin-right: 20px;
  }
`;

const StyledBox = styled(Box)`
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s cubic-bezier(0.22, 1, 0.36, 1),
    transform 0.2s cubic-bezier(0.22, 1, 0.36, 1);
  left: 50%;
  top: calc(100%);
  transform: translate(-50%, -10%) scale(0.8);
  transform-origin: center center;
  box-shadow: 20px 30px 70px rgba(0, 0, 0, 0.2);

  ${StyledIndicationWrapper}:hover & {
    opacity: 1;
    transform: translate(-50%, 0) scale(1);
    pointer-events: all;
  }
`;

const InteractiveVisualization: FC<Props> = ({ image, ...props }) => {
  const [isOnlyAvailableActive, setOnlyAvailableActive] =
    useState<boolean>(false);

  const handleCheckboxChange = () =>
    setOnlyAvailableActive((prevState) => !isOnlyAvailableActive);
  const {
    firstRow: { nodes: firstRow },
    secondRow: { nodes: secondRow },
  } = useStaticQuery<Data>(query);

  const correctSecondRow = [
    secondRow[2],
    secondRow[3],
    secondRow[0],
    secondRow[1],
  ];

  return (
    <StyledWrapper {...props}>
      <StyledNav>
        <StyledList>
          {firstRow.map(({ number, availability, replaceNumber }) => (
            <StyledItem key={number}>
              <CSSTransition
                in={
                  isOnlyAvailableActive
                    ? availability === Availability.AVAILABLE
                    : true
                }
                timeout={200}
                unmountOnExit
              >
                <StyledLink
                  to={`/lokal/${number.toLowerCase()}`}
                  $availability={availability}
                >
                  {replaceNumber
                    ? replaceNumber.toUpperCase()
                    : number.toUpperCase()}
                </StyledLink>
              </CSSTransition>
            </StyledItem>
          ))}
        </StyledList>
        <StyledList>
          {correctSecondRow.map(({ number, availability, replaceNumber }) => (
            <StyledItem key={number}>
              <CSSTransition
                in={
                  isOnlyAvailableActive
                    ? availability === Availability.AVAILABLE
                    : true
                }
                timeout={200}
                unmountOnExit
              >
                <StyledLink
                  to={`/lokal/${number.toLowerCase()}`}
                  $availability={availability}
                >
                  {replaceNumber
                    ? replaceNumber.toUpperCase()
                    : number.toUpperCase()}
                </StyledLink>
              </CSSTransition>
            </StyledItem>
          ))}
        </StyledList>
      </StyledNav>

      <StyledImageWrapper>
        <StyledGatsbyImage
          image={getImage(image as any) as IGatsbyImageData}
          alt="Oleńki Park"
        />
        <StyledIndicationsWrapper $first>
          {firstRow.map(
            ({
              number,
              replaceNumber,
              availability,
              price,
              area,
              gardenArea,
              roomsCount,
              deliveryDate,
            }) => (
              <StyledIndicationWrapper
                $first
                key={number}
                $preventFromDisplay={
                  availability !== Availability.AVAILABLE &&
                  isOnlyAvailableActive
                }
              >
                <StyledIndication
                  to={`/lokal/${number.toLowerCase()}`}
                  $availability={availability}
                >
                  {replaceNumber
                    ? replaceNumber.toUpperCase()
                    : number.toUpperCase()}
                </StyledIndication>
                <StyledBox
                  number={number}
                  replaceNumber={replaceNumber}
                  availability={availability}
                  values={[
                    {
                      name: 'Pow. użytkowa',
                      value: `${area.toLocaleString('pl-PL')} m2`,
                    },
                    {
                      name: 'Pow. ogrodu',
                      value: `ok ${gardenArea.toLocaleString('pl-PL')} m2`,
                    },
                    {
                      name: 'Ilość pokoi',
                      value: roomsCount.toString(),
                    },
                    {
                      name: 'Termin oddania',
                      value: deliveryDate,
                    },
                  ]}
                  price={price}
                />
              </StyledIndicationWrapper>
            )
          )}
        </StyledIndicationsWrapper>

        <StyledIndicationsWrapper $second>
          {correctSecondRow.map(
            ({
              number,
              replaceNumber,
              availability,
              price,
              area,
              gardenArea,
              roomsCount,
              deliveryDate,
            }) => (
              <StyledIndicationWrapper
                $second
                key={number}
                $preventFromDisplay={
                  availability !== Availability.AVAILABLE &&
                  isOnlyAvailableActive
                }
              >
                <StyledIndication
                  to={`/lokal/${number.toLowerCase()}`}
                  $availability={availability}
                >
                  {replaceNumber
                    ? replaceNumber.toUpperCase()
                    : number.toUpperCase()}
                </StyledIndication>
                <StyledBox
                  number={number}
                  replaceNumber={replaceNumber}
                  availability={availability}
                  values={[
                    {
                      name: 'Pow. użytkowa',
                      value: `${area.toLocaleString('pl-PL')} m2`,
                    },
                    {
                      name: 'Pow. ogrodu',
                      value: `ok ${gardenArea.toLocaleString('pl-PL')} m2`,
                    },
                    {
                      name: 'Ilość pokoi',
                      value: roomsCount.toString(),
                    },
                    {
                      name: 'Termin oddania',
                      value: deliveryDate,
                    },
                  ]}
                  price={price}
                />
              </StyledIndicationWrapper>
            )
          )}
        </StyledIndicationsWrapper>
      </StyledImageWrapper>

      <StyledBottomWrapper>
        <StyledLegendWrapper>
          <StyledLegend>Dostępne</StyledLegend>
          <StyledLegend
            color="orange"
            $preventFromDisplay={isOnlyAvailableActive}
          >
            Zarezerwowane
          </StyledLegend>
          <StyledLegend color="red" $preventFromDisplay={isOnlyAvailableActive}>
            Sprzedane
          </StyledLegend>
        </StyledLegendWrapper>

        <StyledCheckboxWraper>
          <StyledCheckbox
            value={isOnlyAvailableActive}
            onChange={handleCheckboxChange}
          />
          <span>Pokaż tylko dostępne</span>
        </StyledCheckboxWraper>
      </StyledBottomWrapper>
    </StyledWrapper>
  );
};

interface AllDatoCmsHouse {
  nodes: {
    number: string;
    replaceNumber: string;
    area: number;
    gardenArea: number;
    roomsCount: number;
    deliveryDate: string;
    availability: Availability;
    price: number;
  }[];
}

interface Data {
  firstRow: AllDatoCmsHouse;
  secondRow: AllDatoCmsHouse;
}

const query = graphql`
  {
    firstRow: allDatoCmsHouse(
      filter: { number: { regex: "/C1|C2|D1|D2/" } }
      sort: { fields: number, order: ASC }
    ) {
      nodes {
        number
        replaceNumber
        area
        gardenArea
        roomsCount
        availability
        price
        deliveryDate(formatString: "DD MMMM YYYY", locale: "PL")
      }
    }
    secondRow: allDatoCmsHouse(
      filter: { number: { regex: "/A2|A1|B2|B1/" } }
      sort: { fields: number, order: DESC }
    ) {
      nodes {
        number
        replaceNumber
        area
        gardenArea
        roomsCount
        availability
        price
        deliveryDate(formatString: "DD MMMM YYYY", locale: "PL")
      }
    }
  }
`;

interface Props {
  image: ChildImageSharpImage;
}

export default InteractiveVisualization;
