import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Input from '@components/atoms/Input/Input';
import Checkbox from '@components/atoms/Checkbox/Checkbox';
import Button from '@components/atoms/Button/Button';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import axios from 'axios';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 525px;
`;

const StyledInputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledInput = styled(Input)<{ $fullWidth?: boolean }>`
  width: 48%;
  margin-bottom: 30px;

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `};

  @media (min-width: 1025px) {
    margin-bottom: 50px;
  }
`;

const StyledFormLabel = styled.label`
  display: flex;
  font-size: 1.2rem;
  align-items: flex-start;
  cursor: pointer;
  width: 100%;
  margin-top: 30px;

  span {
    flex: 1;
    margin-top: -4px;

    span {
      color: ${({ theme }) => theme.primary};
      margin-right: 1px;
    }
  }
`;

const StyledFormCheckbox = styled(Checkbox)`
  margin-right: 10px;
`;

const StyledFormButton = styled(Button)`
  width: 80%;
  margin: auto;

  @media (min-width: 1025px) {
    max-width: 230px;
    margin: 0;
  }
`;

const StyledFormInfo = styled.span`
  font-size: 1.2rem;
  margin: 15px 0 40px;

  span {
    color: ${({ theme }) => theme.primary};
    margin: 0 1px;
  }
`;

const StyledError = styled.span`
  margin-top: 10px;
  color: ${({ theme }) => theme.unavailable};
  font-size: 1.4rem;

  @media (min-width: 1025px) {
    font-size: 1.6rem;
  }
`;

const StyledFormStatus = styled.span<{ $error?: boolean }>`
  color: ${({ theme }) => theme.primary};
  font-weight: ${({ theme }) => theme.fontMedium};
  margin-top: 10px;
  font-size: 1.4rem;

  @media (min-width: 1025px) {
    font-size: 1.6rem;
  }

  ${({ $error }) =>
    $error &&
    css`
      color: ${({ theme }) => theme.unavailable};
    `}
`;

const Form: FC<Props> = ({ rodoAddress }) => {
  const [successfulRecaptcha, setSuccessfulRecaptcha] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [recaptchaError, setRecaptchaError] = useState<boolean>(false);
  const [isSent, setSent] = useState<boolean>(false);
  const [isErrorSent, setErrorSent] = useState<boolean>(false);

  useEffect(() => {
    if (successfulRecaptcha && recaptchaError) {
      setRecaptchaError(false);
    }
  }, [successfulRecaptcha]);

  const onSubmit = (data: any) => {
    if (!successfulRecaptcha) {
      setRecaptchaError(true);
      return;
    }

    if (isSent) return;

    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios
      .post('https://formsubmit.co/ajax/biuro@mimariposa.pl', {
        // prettier-ignore
        'Imię': data.name,
        'E-mail': data.email,
        'Numer telefonu': data.phone,
        // prettier-ignore
        'Wiadomość': data.message,
        _template: 'table',
        _subject: 'Nowa wiadomość z olenkipark.pl!',
      })
      .then(() => setSent(true))
      .catch(() => setErrorSent(true));
  };

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <StyledInput
          placeholder="Imię"
          required
          error={
            errors.name?.type === 'required'
              ? 'To pole jest wymagane!'
              : undefined
          }
          {...register('name', { required: true })}
        />

        <StyledInputsWrapper>
          <StyledInput
            placeholder="E-mail"
            type="email"
            required
            error={
              errors.email?.type === 'required'
                ? 'To pole jest wymagane!'
                : errors.email?.type === 'pattern'
                ? 'To nie wygląda na adres e-mail'
                : undefined
            }
            {...register('email', {
              required: true,
              pattern:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
          />
          <StyledInput placeholder="Telefon" {...register('phone')} />
        </StyledInputsWrapper>
        <StyledInput
          placeholder="Twoja wiadomość"
          required
          $fullWidth
          error={
            errors.message?.type === 'required'
              ? 'To pole jest wymagane!'
              : undefined
          }
          {...register('message', { required: true })}
        />
        <ReCAPTCHA
          sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA as string}
          onChange={(value: any) => setSuccessfulRecaptcha(value)}
        />
        {recaptchaError && (
          <StyledError>Potwierdź, że nie jesteś robotem</StyledError>
        )}

        <StyledFormLabel>
          <StyledFormCheckbox
            required
            {...register('rodo', { required: true })}
          />
          <span>
            <span>*</span> Wyrażam zgodę na przetwarzanie danych osobowych
            zgodnie z ustawą o ochronie danych osobowych, w związku z wysłaniem
            zapytania przez formularz kontaktowy. Podanie danych jest
            dobrowolne, ale niezbędne do przetworzenia zapytania. Zostałem
            poinformowany, że przysługuje mi prawo dostępu do swoich danych,
            możliwości ich poprawiania, żądania zaprzestania ich przetwarzania.
            Administratorem danych osobowych jest <b>{rodoAddress}</b>
          </span>
        </StyledFormLabel>
        {errors.rodo?.type === 'required' && (
          <StyledError>Zgoda jest wymagana</StyledError>
        )}
        <StyledFormInfo>
          Pole oznaczone <span>*</span> są wymagane
        </StyledFormInfo>
        <StyledFormButton secondary disabled={isSent}>
          Wyślij
        </StyledFormButton>
        {isSent && (
          <StyledFormStatus>
            Wiadomość została pomyślnie wysłana!
          </StyledFormStatus>
        )}
        {isErrorSent && (
          <StyledFormStatus $error>
            Wystąpił błąd podczas wysyłania formularza
          </StyledFormStatus>
        )}
      </StyledForm>
    </>
  );
};

interface Props {
  rodoAddress: string;
}

export default Form;
