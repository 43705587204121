import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { useButtonEffects } from '@hooks/useButtonEffects';
import { rippleAnimation } from '@theme/rippleAnimation';
import Icon from '@iconify/react';

const StyledButton = styled.button<{
  $secondary: boolean;
  $tertiary: boolean;
  disabled: boolean;
}>`
  padding: 10px 20px;
  background: ${({ theme }) => theme.gradientPrimaryToPrimaryDarker};
  color: ${({ theme }) => theme.background};
  border: 0;
  user-select: none;
  transition: background 0.3s ease-in-out, transform 0.3s ease-out;
  position: relative;
  overflow: hidden;
  font-size: 1.4rem;
  cursor: pointer;
  text-decoration: none;

  :hover {
    background: ${({ theme }) => theme.gradientPrimaryDarkerToPrimaryDarkest};
  }

  :active {
    transform: scale(0.95);
    background: ${({ theme }) => theme.gradientPrimaryDarkerToPrimaryDarkest};
  }

  .ripple {
    width: 160px;
    height: 160px;
    position: absolute;
    background: #fff;
    border-radius: 50%;
    transform: scale(0);
    pointer-events: none;
    opacity: 0;
    animation: ${rippleAnimation} 0.6s ease-out;
    transform-origin: center center;

    @media (min-width: 1025px) {
      width: 260px;
      height: 260px;
    }
  }

  @media (min-width: 1025px) {
    font-size: 2.2rem;
  }

  ${({ $secondary, theme, disabled }) =>
    $secondary &&
    css`
      background: ${theme.background};
      color: ${theme.primary};
      border: 1px solid ${theme.primary};
      font-weight: ${theme.fontBold};
      font-size: 2rem;
      width: 230px;

      :hover {
        background: ${theme.primary};
        color: ${theme.background};
      }

      :active {
        background: ${theme.primary};
        color: ${theme.background};
      }

      ${disabled &&
      css`
        cursor: not-allowed;
        color: ${({ theme }) => theme.wash};
        border: 1px solid ${({ theme }) => theme.wash};

        :hover,
        :active {
          background: ${theme.background};
          color: ${({ theme }) => theme.wash};
        }
      `}
    `}

  ${({ $tertiary, theme }) =>
    $tertiary &&
    css`
      font-weight: ${theme.fontBold};
      display: flex;
      align-items: center;

      &,
      :hover,
      :active {
        background: transparent;
        color: ${theme.primary};
      }

      @media (min-width: 1025px) {
        font-size: 2.4rem;
      }
    `}
`;

const StyledIcon = styled(Icon)`
  font-size: 2rem;
  margin-right: 5px;

  @media (min-width: 1025px) {
    font-size: 4rem;
    margin-right: 15px;
  }
`;

const Button: FC<Props> = ({
  children,
  className,
  onClick,
  secondary,
  tertiary,
  icon,
  as,
  to,
  disabled,
  ...props
}) => {
  const { onMouseDown } = useButtonEffects();

  return (
    <StyledButton
      className={className}
      onMouseDown={!tertiary ? onMouseDown : undefined}
      onClick={onClick}
      $secondary={!!secondary}
      $tertiary={!!tertiary}
      as={as}
      to={to}
      disabled={disabled}
      {...props}
    >
      {icon && <StyledIcon icon={icon} />}
      {children}
    </StyledButton>
  );
};

interface Props {
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  secondary?: boolean;
  tertiary?: boolean;
  icon?: any;
  as?: any;
  to?: string;
  disabled?: boolean;
}

export default Button;
