import React, { FC } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

const StyledGatsbyImage = styled(GatsbyImage)``;

const Logo: FC<Props> = ({ className }) => {
  const image = getImage(useStaticQuery(query).file) as IGatsbyImageData;

  return (
    <StyledGatsbyImage
      image={image}
      alt="Oleńki Park Logo"
      className={className}
    />
  );
};

const query = graphql`
  {
    file(name: { eq: "logo" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 80
          placeholder: TRACED_SVG
        )
      }
    }
  }
`;

interface Props {
  className?: string;
}

export default Logo;
