import React, { FC } from 'react';
import styled from 'styled-components';
import pattern from '@assets/images/pattern.jpg';
import patternImg2x from '@assets/images/pattern@2x.jpg';
import ScrollTopButton from '@components/atoms/ScrollTopButton/ScrollTopButton';
import { NAVIGATION_ITEMS } from '@config';
import Icon from '@iconify/react';
import heartIcon from '@iconify-icons/emojione/red-heart';
import { goToSection } from '@utils/goToSection';
import facebookIcon from '@iconify-icons/ic/round-facebook';
import { graphql, useStaticQuery } from 'gatsby';

const StyledWrapper = styled.footer`
  background-image: url(${pattern});
  width: 100%;
  padding: 20px 5% 40px;
  position: relative;

  @media (min-width: 1025px) {
    padding: 65px 5% 45px;
    background: url(${patternImg2x});
  }
`;

const StyledInnerWrapper = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.background};
  max-width: 1500px;
  margin: auto;

  @media (min-width: 1025px) {
    display: flex;
    justify-content: space-between;
  }
`;

const StyledCenterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 1025px) {
    width: unset;
  }

  @media (min-width: 1224px) {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
  }
`;

const StyledSocialMediaAndDisclaimerWrapper = styled.div`
  display: none;
  flex-direction: column;
  max-width: 260px;

  @media (min-width: 1025px) {
    display: flex;
  }

  @media (min-width: 1656px) {
    max-width: 360px;
  }
`;

const StyledFacebookWrapper = styled.a`
  color: ${({ theme }) => theme.background};
  text-decoration: none;
  display: flex;
  align-items: center;

  span {
    display: none;

    @media (min-width: 1656px) {
      display: block;
    }
  }
`;

const StyledFacebookIcon = styled(Icon)`
  margin-right: 15px;
  font-size: 5rem;
`;

const StyledDisclaimer = styled.div`
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.fontLight};
  letter-spacing: 0;
  margin-top: 25px;
  display: none;

  @media (min-width: 1224px) {
    display: block;
  }

  @media (min-width: 1656px) {
    font-size: 1.4rem;
  }
`;

const StyledList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 1025px) {
    flex-direction: row;
  }
`;

const StyledItem = styled.li`
  padding: 10px 0;

  @media (min-width: 1025px) {
    padding: 0;
    margin-right: 50px;

    :last-of-type {
      margin-right: 0;
    }
  }
`;

const StyledLink = styled.a`
  color: inherit;
  text-decoration: none;
  text-align: center;
  font-size: 1.8rem;
  font-weight: ${({ theme }) => theme.fontMedium};
`;

const StyledAuthor = styled.span`
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

  a {
    color: inherit;
    text-decoration: none;
    font-weight: ${({ theme }) => theme.fontBold};
    margin-left: 5px;
  }

  @media (min-width: 1025px) {
    margin-top: 40px;
  }
`;

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  font-size: 2.5rem;
`;

const StyledCopyright = styled.span`
  font-weight: ${({ theme }) => theme.fontMedium};
  margin-top: 15px;

  @media (min-width: 1025px) {
    margin-top: 30px;
  }
`;

const StyledScrollTopButton = styled(ScrollTopButton)`
  position: absolute;
  top: 30px;
  right: 30px;

  @media (min-width: 1025px) {
    position: static;
  }

  @media (min-width: 1656px) {
    margin-right: 10%;
  }
`;

const Footer: FC = () => {
  const { datoCmsSetting }: { datoCmsSetting: { facebook: string } } =
    useStaticQuery(query);

  return (
    <StyledWrapper>
      <StyledInnerWrapper>
        <StyledSocialMediaAndDisclaimerWrapper>
          <StyledFacebookWrapper
            href={datoCmsSetting.facebook}
            target="_blank"
            rel="noopener"
          >
            <StyledFacebookIcon icon={facebookIcon} />
            <span>Obserwuj nas na Facebooku</span>
          </StyledFacebookWrapper>

          <StyledDisclaimer>
            Treści znajdujące się na stronie internetowej nie stanowią oferty w
            rozumieniu przepisów prawa i winny być interpretowane wyłącznie jak
            informacja, o których jest mowa w art. 71 Kodeksu Cywilnego.
          </StyledDisclaimer>
        </StyledSocialMediaAndDisclaimerWrapper>

        <StyledCenterWrapper>
          <StyledList>
            {NAVIGATION_ITEMS.map(({ name, to }) => (
              <StyledItem key={to}>
                <StyledLink href={to} onClick={(e) => goToSection(e, to)}>
                  {name}
                </StyledLink>
              </StyledItem>
            ))}
          </StyledList>

          <StyledAuthor>
            Stworzone z
            <StyledIcon icon={heartIcon} />
            przez <a href="https://rend.pro/offer/investment-website/">RendPro.com</a>
          </StyledAuthor>

          <StyledCopyright>Copyright &copy; 2021 Oleńki Park</StyledCopyright>
        </StyledCenterWrapper>

        <StyledScrollTopButton />
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    datoCmsSetting {
      facebook
    }
  }
`;

export default Footer;
